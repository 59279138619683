 .landingPage .header .header-body {
   margin-top: 30px;
   display: flex;
   align-items: center;

   justify-content: space-between;

   padding: 20px;
   background-color: #AFC9F3;
   border-radius: 50px;
   position: fixed;
   width: 1300px;
   top: 0;

   z-index: 1000;
   position: relative;

 }
 .padk{
  padding: 1% 5%;
 }

 .nav-bar {
   background-color: #AFC9F3;
   padding: 10px;
 }

 .nav-bar button {
   border-radius: 10px;
   padding: 10px;
 }

 .landingPage .header img {
   width: 170px;
   max-width: 100%;

   height: auto;

 }

 .info-body {
   width: 50%;
   margin-top: 7%;

 }

 .info-body h1 {
   text-align: start;
   padding: 0px 9.5%;
   margin-top: 10px;
 }








 .landingPage .header .buttons-info .c_register {
   background-color: red;
   color: white;
   padding: 10px 5%;
   border-radius: 10px;
   cursor: pointer;
   margin-right: 10px;
   border: none;
   transition: background-color 0.3s ease, transform 0.3s ease;
 }

 .landingPage .header .buttons-info .register:hover {
   transform: scale(1.05);
 }

 .landingPage .header .buttons-info .c_register:hover {
   transform: scale(1.05);
 }

 .landingPage .search_info {
   margin-top: 10%;
   margin-left: 10px 5.3%;




 }

 .imgs {
   position: absolute;
   right: 0;

 }

 .imgs img {
   width: 100%;
   height: 700px;
   margin-top: 5%;
 }

 .landingPage .search_info input {
   height: 50px;
   border-right: 1px solid black;
   border-top: 1px solid rgb(218, 214, 214);
   border-bottom: 1px solid rgb(210, 205, 205);
   border-left: 1px solid rgb(214, 208, 208);

 }

 .landingPage .search_info .skills {
   border-top-left-radius: 30px;
   border-bottom-left-radius: 30px;
 }

 .landingPage .search_info button {
   height: 50.2px;
   padding: 0px 30px;
   border-left: 0px solid black;
   background-color: red;
   color: white;
   border-top-right-radius: 30px;
   border-bottom-right-radius: 30px;
   border: none;
 }

 /* Base styling */
 .header-content {
   display: flex;
   flex-direction: column;

   padding: 20px;
   gap: 15px;
   margin-top: 40px;
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
   text-align: left;
   margin-left: 10px;
 }

 .header-content h1 {
   font-size: 2.5rem;
   font-weight: bold;
   color: #333;
   margin-bottom: 10px;
 }

 .header-content .tagline {
   font-size: 1.3rem;
   color: #555;
   margin-bottom: 15px;
 }

 .header-content span {
   font-size: 15px;
   color: #666;
 }

 .header-content button {
   padding: 12px 25px;
   font-size: 1rem;
   background-color: #007bff;
   color: white;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
   margin-top: 15px;
 }

 .header-content button:hover {
   background-color: #0056b3;
 }


 .header-content button:hover {
   background-color: #0056b3;
 }

 .head_card {
   background-color: #CDDEF8;
   width: 100%;
   max-width: 1350px;
   padding: 20px;
   border-radius: 25px;
   box-sizing: border-box;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0 auto;
   margin-top: 200px;
 }


 .head_card p {
   margin: 0;
   color: #000000;
   font-size: 1.1em;
   text-align: left;
 }


 .trynow_btn {
   margin-top: 20px;
   padding: 10px 20px;
   margin-right: 70px;
 }


 .cards {
   background-color: #FFFFFF;
   border: solid 1px gainsboro;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   padding: 30px;
   margin-top: 70px;
   border-radius: 10px;

 }

 .job_intern {
   display: flex;
   gap: 30px;
   margin-top: 80px;
   border-bottom: 6px solid;
   border-bottom-color: #547DBF;
   margin-left: 30px;
   flex-wrap: wrap;
   /* Allows wrapping for smaller screens */
   justify-content: center;
   /* Centers items on small screens */
 }

 .job_intern h1 {
   font-weight: normal;
   margin-bottom: 10px;
 }



 .edu-img {
   width: 50px;
 }



 .vertical-card {
   border: solid 5px #f5f5f5;
   padding: 30px;



 }

 .vertical-card h1 {
   font-size: 16px;
   margin-top: 10px;

 }

 .vertical-card:hover {
   cursor: pointer;
   transform: scale(1.05);
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   transition: transform 0.3s ease, box-shadow 0.3s ease;
 }











 .empty-box {
   padding: 30px;
   margin-top: 30px;
   border-radius: 30px;
   margin-left: 50px;
   background-color: whitesmoke;
   width: 93%;
   display: flex;

 }

 .section {
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   /* Adds space between sections */
   margin-top: 28px;

 }

 .dm-img {
   width: 40px;
   margin-right: 8px;
   /* Minimal gap between image and text */
   margin-bottom: 140px;
 }

 .section h1 {
   font-size: 20px;
   text-align: left;
 }

 .section p {
   text-align: left;
 }






 @media (max-width: 375px) {
   .h1-contant h1 {

     font-size: small;
     font-weight: normal;

   }
 }

 @media (max-width: 425px) {
   .mobile-res {
     display: block;
   }
 }

 @media (max-width: 576px) {
   .buy_card button {
     padding: 10px 15px;
     margin-top: 30px;
   }


   .mobile-res {
     display: block;
   }

   .top-content h1 {
     font-size: 30px;

   }

   .top-content {
     display: flex;
   }

   .landingPage .header .header-body {
     display: block;
   }



   /* Optional: Add a similar effect for touch events */
   .buy_card:active,
   .buy_card_gold:active {
     margin-top: -10px;
     /* Simulate hover on touch devices */
     box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
     /* Simulate box shadow on touch devices */
   }
 }



 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }

 .career-container {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   gap: 40px;
   padding: 20px;
   margin-top: 50px;

 }

 .career {
   background-color: #CDDEF8;
   border-radius: 4px;
   padding: 20px;
   width: 100%;
   max-width: 350px;
   text-align: center;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

 }

 .career span {
   display: block;
   font-weight: 500;
   margin-top: 20px;
 }

 .career_img {
   width: 100%;
   max-width: 300px;
   margin-top: 20px;
   border-radius: 8px;
 }

 /* Button Section */
 .career_btn {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 20px;
   width: 100%;
   max-width: 350px;
   padding: 20px;
   border: 1px solid #ddd;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }

 .career_btn div {
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: 100%;
 }

 .career_btn button {
   background-color: #fff;
   color: #333;
   border: 1px solid #ddd;
   padding: 10px 20px;
   border-radius: 5px;
   font-size: 16px;
   cursor: pointer;
   transition: all 0.3s ease;
 }

 .career_btn button:hover {
   background-color: #f0f0f0;
   transform: translateY(-2px);
 }

 /* Responsive Adjustments */


 @media (max-width: 1440px) {
   .mobile-res {
     display: none;
   }
 }

 @media (max-width: 768px) {
   .career-container {
     flex-direction: column;
     align-items: center;
     gap: 30px;
   }

   .mobile-res {
     display: none;
   }

   .landingPage .search_info {
     display: flex;

   }

   .landingPage .search_info input {
     width: 100px;
     margin-left: 10px;
   }

   .career {
     width: 80%;
     max-width: 100%;
   }

   .career_btn {
     flex-direction: column;
     gap: 20px;
     width: 80%;
     max-width: 100%;
   }

   .career_img {
     width: 80%;
     max-width: 280px;
   }
 }

 @media (max-width: 480px) {
   .career-container {
     padding: 15px;
   }

   .mobile-res {
     display: block;
   }

   .trynow_btn {
     margin-right: 20px;
   }

   .career {
     width: 100%;
     max-width: 100%;
     padding: 15px;
   }

   .career_img {
     width: 100%;
     max-width: 250px;
     margin-top: 10px;
   }

   .career_btn {
     padding: 15px;
     width: 100%;
   }

   .career_btn button {
     width: 100%;
     font-size: 14px;
   }

   .mobile-res {
     width: 800px;
   }
 }


 @media (max-width: 425px) {


   .mobile-res {
     width: 800px;

   }

   .empty-box {
     display: block;
   }

   .section {
     display: flex;
   }
 }



 /* Flexbox for Desktop: side by side */
 .section {
   display: flex;
   /* Side by side on larger screens */
   justify-content: space-between;
   align-items: center;
   /* Vertically center content */
   margin-bottom: 30px;
   /* Add space between sections */
 }

 .dm-img {
   width: 100%;
   max-width: 300px;
   /* Limit image width for desktop */
   height: auto;
   margin-right: 20px;
 }

 /* Responsive Layout: Stack vertically */
 @media (max-width: 768px) {
   .section {
     flex-direction: column;
     /* Stack vertically on mobile */
     text-align: center;
     /* Center text on mobile */
   }

   .dm-img {
     margin-bottom: 20px;
     /* Add space between image and text */
     max-width: 100%;
     /* Allow images to take full width */
   }

   .col-12 {
     margin-bottom: 20px;
     /* Add spacing between sections */
   }
 }

 /* Flexbox for Desktop: side by side */
 .section {
   display: flex;
   /* Side by side on larger screens */
   justify-content: space-between;
   align-items: center;
   /* Vertically center content */
   margin-bottom: 30px;
   /* Add space between sections */
 }

 .dm-img {
   width: 100%;
   max-width: 300px;
   /* Limit image width for desktop */
   height: auto;
   margin-right: 20px;
 }

 /* Responsive Layout: Stack vertically */
 @media (max-width: 768px) {
   .section {
     flex-direction: column;
     /* Stack vertically on mobile */
     text-align: center;
     /* Center text on mobile */
   }

   .dm-img {
     margin-bottom: 20px;
     /* Add space between image and text */
     max-width: 100%;
     /* Allow images to take full width */
   }

   .col-12 {
     margin-bottom: 20px;
     /* Add spacing between sections */
   }
 }


 @media (max-width: 320px) {
   .header-content {

     gap: 10px;
     padding: 8px;
   }

   .media {
     margin-top: 10px;
     margin-left: 50px;


   }

   .header-content h1 {
     font-size: 1.4rem;
   }

   .header-content .tagline {
     font-size: 0.9rem;
   }

   .header-content span {
     font-size: 0.8rem;
   }

   .header-content button {
     font-size: 0.8rem;
     padding: 6px 15px;
   }
 }

 @media (max-width: 480px) {
   .media {
     margin-left: 50px;
     margin-top: 500px;
   }
 }

 /* Small screens (mobile) */
 @media (max-width: 480px) {
   .header-content {
     margin-top: 100px;
     /* Adjust content positioning on mobile */
     gap: 12px;
     padding: 10px;
   }

   .header-content h1 {
     font-size: 1.6rem;
   }

   .header-content .tagline {
     font-size: 1rem;
   }

   .header-content span {
     font-size: 0.9rem;
   }

   .header-content button {
     font-size: 0.85rem;
     padding: 8px 18px;
   }

   .media {
     margin-top: 10px;
     margin-left: 50px;

   }

 }

 /* Extra-small screens (very narrow mobile) */



 @media (max-width: 768px) {
   .header-content {
     margin-top: 130px;
     padding: 15px;
     gap: 18px;
   }

   .header-content h1 {
     font-size: 2rem;
   }

   .header-content .tagline {
     font-size: 1.1rem;
   }

   .header-content span {
     font-size: 1rem;
   }

   .header-content button {
     font-size: 0.9rem;
     padding: 10px 20px;
   }

   .media {
     margin-top: 500px;
     margin-left: 50px;



   }

 }




 @media (max-width: 375px) {
   .searchbar {

     margin-right: 10px;
     text-align: left;

   }
 }

 @media (max-width: 420px) {
   .searchbar {

     margin-right: 10px;
     text-align: left;

   }
 }

 @media (max-width: 768px) {
   .searchbar {

     margin-right: 10px;
     text-align: left;

   }
 }

 @media (max-width: 320px) {
   .searchbar {

     margin-right: 10px;
     text-align: left;

   }
 }

 /* Navbar Container */
 .navbar-container {
   width: 100%;
   background-color: #CDDEF8;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   position: sticky;
   top: 0;
   z-index: 1000;
   padding: 0.5rem 1.5rem;

 }

 /* Navbar */
 .navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 1200px;
   margin: 0 auto;
   padding: 0.5rem 0;
   position: relative;
   margin-top: 10px;
 }

 /* Logo */
 .navbar-logo {
   max-height: 50px;
 }

 /* Hamburger Icon */
 .navbar-toggle {
   display: none;
   cursor: pointer;
   z-index: 1100;
   /* Ensuring the hamburger icon is clickable above the links */
 }

 /* Navbar Links */
 .navbar-links {
   display: flex;
   gap: 1.5rem;
   justify-content: flex-end;
   align-items: center;
 }

 .navbar-links ul {
   display: flex;
   list-style: none;
   gap: 1.5rem;
 }

 .navbar-links li {
   display: flex;
   align-items: center;
 }

 .navbar-links .btn {
   padding: 0.5rem 1.5rem;
   border-radius: 5px;
   font-size: 1rem;
   font-weight: 600;
   border: none;
   cursor: pointer;
   transition: all 0.3s ease;
 }

 .navbar-links .btn:hover {
   background-color: red;
 }

 .login-btn {
   background-color: #CDDEF8;
   color: black;
   padding: 8px;
   border-radius: 5px;
   font-weight: bold;
   border: solid 1px #547DBF;

 }

 .navbar-links .btn:hover {
   opacity: 0.8;
 }

 /* Mobile Styles */
 @media (max-width: 768px) {
   .navbar-toggle {
     display: block;
   }

   .navbar-links {
     position: fixed;
     top: 0;
     right: -100%;
     width: 70%;
     height: 100%;
     background-color: #ffffff;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 2rem;
     transition: right 0.3s ease-in-out;
     z-index: 999;

   }

   .navbar-links.active {
     right: 0;
     /* Slide-in effect */
   }

   .navbar-links ul {
     flex-direction: column;
     align-items: center;
     width: 100%;
     margin-left: 125px;
   }

   .navbar-links li {
     width: 100%;
     text-align: center;
   }

   .navbar-links .btn {
     width: 50%;
     padding: 12px;
     font-size: 16px;
   }

   .navbar-links .login-btn {
     width: 50%;
     padding: 12px;
     font-size: 16px;
   }



   /* Prevent page scrolling when menu is open */
   body.menu-open {
     overflow: hidden;
   }
 }

 @media (min-width: 769px) {
   .navbar-links {
     display: flex;
     width: auto;
   }
 }