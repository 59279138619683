 
.slick-prev, .slick-next {
    font-size: 24px; /* Arrow size */
    color: white; /* Arrow color */
    background-color: red; /* Red background */
    border-radius: 50%;  
    width: 40px; /* Arrow size */
    height: 40px; /* Arrow size */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Position the arrows */
  .slick-prev {
    left: 10px; /* Position left arrow */
  }
  
  .slick-next {
    right: 10px; /* Position right arrow */
  }
  
  /* Hover effect for Slick arrows */
  .slick-prev:hover, .slick-next:hover {
    background-color: darkred; /* Darker red on hover */
  }
  
  /* Custom arrows only for mobile screens */
  @media (max-width: 768px) {
    /* Hide default Slick arrows on mobile */
    .slick-prev, .slick-next {
      display: none !important;
    }
  
    /* Custom arrows for mobile view */
    .job-slick-prev, .job-slick-next {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 32px; /* Custom arrow size */
      color: #333;
      cursor: pointer;
      background-color: transparent;
      border: none;
      z-index: 10;
    }
  
    .job-slick-prev {
      left: 10px; /* Position left custom arrow */
    }
  
    .job-slick-next {
      right: 10px; /* Position right custom arrow */
    }
  }
  