
.hero {
  height: 400px;
  border-radius: 33px;
  margin:20px;
  background-image: url('../assets/gropImg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;  
  justify-content: center; 
  align-items: center;  
}
.hero-section {
  height: 600px;
  background-color: #CDDEF8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-sectionTrue {
  height: 600px;
  background-color: #0E41B0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero h1 {
  font-size: 3rem;
  font-weight: bold;
  z-index: 1;  
  margin-left:10px;
}

.card1{
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin:1px
}

.card1 button{
  padding: 5px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  
}

.card1 p{
  color:#707070 ;
  font-size: 12px;
  margin-top: 10px;
  text-align: start;
}


.re-img{
  width: 50px;
  border-radius: 50%;
  margin-top:10px;
}
.card1 h5{
  margin-left:20px;
  margin-top:10px;
}
.card1:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out; 
}
.reviewButton{
  display:flex;
  justify-content: center; 
}
.custom-nav-link{
  text-decoration:none;
  color: inherit;
}
.heightArea{
  height:600px;
}
.image-container {
  position: relative;
  width: 100%;
  max-width: 400px; 
}

.image {
  width: 100%;
  height: auto;
}

.text {
  position: absolute;
  top: 150px; 
  left:200px;
  transform: translateX(-50%);
  color: #F72C25;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

.stat-card {
background: #fff;
border-radius: 12px;  
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
padding: 30px;
transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;  
width: 75%;  
}

.stat-card:hover {
transform: scale(1.05);  
box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);  
}

.stat-number {
font-size: 3rem;
font-weight: bold;
color: #343a40;
margin: 0;
}

.stat-text {
font-size: 1rem;
color: #6c757d;
margin-bottom: 25px;
}

.stat-card-body {
text-align: center;
}

@media (max-width: 768px) {
.stat-card {
  padding: 20px;
}

.stat-number {
  font-size: 2.5rem;
}

.stat-text {
  font-size: 0.9rem;
}
}
.imgLap{
width:100%;
height:100%;
}
.colourBlue:hover{
background-color:#0E41B0;
height:50px;
padding:20px;
border-radius:10px;
}
.textCenter{
text-align:left;
}
@media (max-width: 768px){
.hero-section {
  height: 900px;
  background-color: #CDDEF8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-allign : center;
}
.marginSlider{}
.textCenter{
  text-align:center;
  margin-top:0;
}
}