.Box-border {
    width: 80%;
    max-width: 600px;
    height: auto;
    padding: 30px;
    border: 1px inset #1C6EA4;
    margin: 30px auto;
    border-radius: 10px;
}
.otp_input {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 50px;
}

.otp_input input {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    font-size: 32px;
    text-align: center;
    padding: 10px;
}

.nxt_btn {
    font-size: large;
    padding: 12px 30px;
    border-radius: 10px;
    margin: 40px auto;
    display: block;
}
@media (max-width: 768px) {
    .Box-border {
        width: 90%;
    }

    .otp_input input {
        width: 60px;
        height: 60px;
        font-size: 28px;
    }

    .nxt_btn {
        font-size: medium;
        padding: 10px 25px;
    }
}

@media (max-width: 480px) {
    .Box-border {
        width: 95%;
        padding: 20px;
    }

    .otp_input input {
        width: 50px;
        height: 50px;
        font-size: 24px;
    }

    .nxt_btn {
        font-size: small;
        padding: 8px 20px;
    }
}
