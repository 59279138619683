   h1, h2, h3, p {
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3 {
    font-family: 'Roboto', sans-serif;
    color: #333;
  }
  
  h1 {
    font-size: 32px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 28px;
    font-weight: 600;
  }
  
  h3 {
    font-size: 24px;
    font-weight: 500;
  }
  
  /* p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
  } */
  /* .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  } */
  
  /* .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    padding: 30px;
  } */
  
  .card h1 {
    color:#E94334;
    ;
    font-size: 26px;
    margin-bottom: 15px;
  }
  
  .card h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  /* .card p {
    font-size: 18px;
    color: #555;
    margin-bottom: 12px;
  } */
  
  .card p span {
    font-weight: 600;
  }
  
  button.btn-dark {
    background-color: #E94334;
    color: white;
    border: none;
    padding: 12px 25px;
    font-size: 16px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  button.btn-dark:hover {
    background-color: #0056b3;
  }
  
  h1.page-header {
    font-size: 36px;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .page-header .subheading {
    font-size: 18px;
    color: #777;
  }
  
  aside {
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 100vh;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  /* main {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
   */
  .main-section {
    margin-bottom: 40px;
  }
  
  .main-section h2 {
    font-size: 28px;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .main-section p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 10px;
  }
  .mt-4 {
    margin-top: 40px;
  }
  
  .mt-3 {
    margin-top: 30px;
  }
  
  .mb-4 {
    margin-bottom: 40px;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    .card {
      padding: 20px;
    }
  
    .col-md-4 {
      width: 100%;
    }
  
    .col-md-8 {
      width: 100%;
    }
  }
  