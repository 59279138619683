body {
    font-family: 'Poppins', sans-serif;
  }
  
  :root {
    --background-dark: #2d3548;
    --text-light: rgba(255, 255, 255, 0.6);
    --text-lighter: rgba(255, 255, 255, 0.9);
    --accent-color: #0E1DB0;
    --spacing-s: 16px;
    --spacing-m: 24px;
    --spacing-l: 32px;
    --spacing-xl: 48px;
    --spacing-xxl: 64px;
    --width-container: 1200px;
  }
  
  .timeline {
    position: relative;
    max-width: var(--width-container);
    margin: 0 auto;
    padding: var(--spacing-xxl) 0;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 8px;
    background: linear-gradient(190deg, #0E1DB0 0%, #B5E2FA 100%) !important;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    height: 0;
    transition: height 1.5s ease-out, opacity 1.5s ease-out;
  }
  
  .timeline-container {
    position: relative;
    width: 50%;
    padding: var(--spacing-l) var(--spacing-xl);
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .timeline-container.left {
    left: 0;
  }
  
  .timeline-container.right {
    left: 50%;
  }
  
  .timeline-container::before {
    content: "";
    position: absolute;
    top: 30px;
    width: 24px;
    height: 24px;
    background: var(--text-lighter);
    border: 4px solid var(--accent-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 99999;
  }
  
  .content {
    padding: var(--spacing-xl);
    background-color: var(--text-lighter);
    position: relative;
    border-radius: var(--spacing-l);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    text-align: center;
    color: var(--background-dark);
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .content:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
  }
  
  .icon {
    font-size: 32px;
    margin-right: var(--spacing-s);
    color: #0E1DB0;
  }
  
  a.content-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  @media screen and (max-width: 600px) {
    .timeline::after {
      left: 31px;
    }
  
    .timeline-container {
      width: 100%;
      padding-left: 80px;
      padding-right: 40px;
      margin-bottom: var(--spacing-l);
    }
  
    .timeline-container.left::before,
    .timeline-container.right::before {
      left: 31px;
    }
  
    .timeline-container.right {
      left: 0%;
    }
  
    .timeline-container.left,
    .timeline-container.right {
      left: 0;
    }
  }
  
  .show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .timeline.show-line::after {
    height: 100%;
    opacity: 1;
  }
  
  .timeline.hide-line::after {
    height: 0;
    opacity: 0;
  }
  