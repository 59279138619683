.slider {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    /* background-color: #f9f9f9; */
    padding: 20px 0;
  }
  
  .track {
    display: flex;
    gap: 15px;
    animation: scroll 20s linear infinite;
  }
  
  .item {
    flex: 0 0 auto;
    width: 150px;
    height: auto;
  }
  
  .item img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  @keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  @media (max-width: 768px) {
    .item {
      width: 100px;
    }
  }
  
  @media (max-width: 576px) {
    .item {
      width: 80px;
    }
  }
  