
.auto-slider-wrapper {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
}


.auto-slider-image {
  width: 100%;
  height: 400px; 
  object-fit: cover;
  /* border-radius: 10px; */
}

.slick-prev,
.slick-next {
  font-size: 30px;
  color: #fff;
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  font-size: 12px;
   color: black;
}

.slick-dots li.slick-active button:before {
   
}


.slick-prev:hover,
.slick-next:hover {
  color: #007bff;
  transition: color 0.3s ease;
}
